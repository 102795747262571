@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.container {
  display: flex;
  flex-direction: column;
  padding-left: 200px;
  padding-right: 200px;
}

@media (min-width: 1930px) {
  .container {
    padding: 0;
    margin: 0 auto;
    max-width: 1520px;
  }
}

@media (max-width: 1100px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.subTitleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .subTitleWrapper {
    margin-bottom: 30px;
  }
}

.subTitle {
  @extend %font-page-section-name;
}

@media (max-width: 1100px) {
  .subTitle {
    width: 250px;
  }
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

@media (max-width: 1100px) {
  .subTitleLine {
    width: 200px;
  }
}

.title {
  @extend %font-page-section-title;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .title {
    margin-bottom: 50px;
  }
}

.mapWrapper {
  position: relative;
}

.map {
  width: 100%;
  height: 700px;
  border-radius: 20px;
  border: 1px solid $violet;
}

@media (max-width: 1750px) {
  .map {
    height: 620px;
  }
}

@media (max-width: 1540px) {
  .map {
    height: 550px;
  }
}

@media (max-width: 1285px) {
  .map {
    height: 460px;
  }
}

.contactsWrapper {
  position: absolute;
  top: 51%;
  left: 62%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 35px;
  border: 1px solid $violet;
  border-radius: 20px;
  background-color: #fff;
  max-width: 550px;
}

@media (max-width: 1750px) {
  .contactsWrapper {
    left: 59%;
    top: 48.3%;
    gap: 28px;
  }
}

@media (max-width: 1540px) {
  .contactsWrapper {
    left: 55%;
    top: 49.3%;
    padding: 27px;
    gap: 25px;
  }
}

@media (max-width: 1366px) {
  .contactsWrapper {
    left: 54%;
    top: 47.3%;
    padding: 24px;
    gap: 22px;
    max-width: 350px;
  }
}

@media (max-width: 1285px) {
  .contactsWrapper {
    left: 58%;
    top: 43.7%;
    padding: 20px;
    gap: 20px;
    max-width: 300px;
  }
}

@media (max-width: 1100px) {
  .contactsWrapper {
    position: static;
    margin-top: 30px;
    border-radius: 12px;
  }
}

.contacts {
  border-left: 1px solid $violet;
  padding-left: 15px;
  gap: 10px;
}

.contacts p {
  color: $text-dark;
  font-size: 18px;
  font-family: Evolventa;
  line-height: 130%;
  text-decoration: underline;
}

.contacts a,
.contacts p {
  color: $text-dark;
  font-size: 18px;
  font-family: Evolventa;
  line-height: 130%;
  cursor: pointer;
}

@media (max-width: 1750px) {
  .contacts a,
  .contacts p {
    font-size: 17px;
  }
}

@media (max-width: 1540px) {
  .contacts a,
  .contacts p {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .contacts a,
  .contacts p {
    font-size: 15px;
  }
}

@media (max-width: 1285px) {
  .contacts a,
  .contacts p {
    font-size: 14px;
  }
}

.contactsTitle {
  color: $violet;
  font-size: 25px;
  font-family: Evolventa;
  line-height: 120%;
}

@media (max-width: 1750px) {
  .contactsTitle {
    font-size: 22px;
  }
}

@media (max-width: 1540px) {
  .contactsTitle {
    font-size: 18px;
  }
}

@media (max-width: 1285px) {
  .contactsTitle {
    font-size: 16px;
  }
}

@media (max-width: 1100px) {
  .contactsTitle {
    margin-bottom: 10px;
  }
}

.textDecorationNone {
  text-decoration: none !important;
}
