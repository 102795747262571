@import 'shared/styles/colors';

.productWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  gap: 20px;
  width: 32.1%;
}

@media (max-width: 1100px) {
  .productWrapper {
    max-width: 100%;
    width: 100%;
  }
}

.productImg {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 487px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  transition: 0.3s all;
  padding: 115px 30px;
  overflow: hidden;
}

@media (min-width: 1930px) {
  .productImg {
    background-size: cover;
  }
}

@media (max-width: 1750px) {
  .productImg {
    height: 430px;
    padding: 100px 25px;
    gap: 15px;
  }
}

@media (max-width: 1540px) {
  .productImg {
    height: 390px;
    padding: 90px 25px;
  }
}

@media (max-width: 1449px) {
  .productImg {
    height: 390px;
    padding: 83px 24px;
  }
}

@media (max-width: 1366px) {
  .productImg {
    height: 330px;
    padding: 75px 23px;
    gap: 10px;
  }
}

@media (max-width: 1285px) {
  .productImg {
    height: 310px;
    padding: 60px 20px;
  }
}

@media (max-width: 1100px) {
  .productImg {
    width: 100%;
    height: 250px;
  }
}

.link {
  color: $text-dark;
  font-size: 30px;
  font-family: Evolventa;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

@media (max-width: 1750px) {
  .link {
    font-size: 27px;
  }
}

@media (max-width: 1540px) {
  .link {
    font-size: 24px;
  }
}

@media (max-width: 1285px) {
  .link {
    font-size: 20px;
  }
}

.link:hover {
  opacity: 0.8;
}

.link:active {
  opacity: 0.5;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.description {
  color: #fff !important;
  text-align: center;
  font-size: 18px;
  font-family: Evolventa;
  font-weight: 400;
  max-height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1750px) {
  .description {
    font-size: 16px;
    max-height: 135px;
  }
}

@media (max-width: 1540px) {
  .description {
    font-size: 15px;
    max-height: 120px;
  }
}

@media (max-width: 1366px) {
  .description {
    font-size: 14px;
    max-height: 110px;
  }
}

@media (max-width: 1285px) {
  .description {
    font-size: 12px;
    max-height: 100px;
  }
}

.title {
  align-self: center;
  color: #fff;
  font-family: Evolventa;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

@media (max-width: 1750px) {
  .title {
    font-size: 24px;
  }
}

@media (max-width: 1540px) {
  .title {
    font-size: 22px;
  }
}

@media (max-width: 1366px) {
  .title {
    font-size: 20px;
  }
}

@media (max-width: 1285px) {
  .title {
    font-size: 18px;
  }
}

.displayNone {
  display: none;
}

.productImg:hover {
  .displayNone {
    display: flex;
  }

  background-image: none !important;
  background-color: $violet;

  // background-color: rgba(24, 9, 200, 0.7);
  // background-repeat: no-repeat;
  // background-image: rgba(24, 9, 200, 0.7);
  // background-blend-mode: overlay, normal;
}

@media (max-width: 1100px) {
  .productImg:hover {
    .displayNone {
      display: none;
    }
    background-color: transparent;
    background-image: none;
  }
}

.widthFitContent {
  width: fit-content;
  align-self: center;
}
