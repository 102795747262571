@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
}

.subTiltleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .subTiltleWrapper {
    margin-bottom: 30px;
  }
}

.subTitle {
  @extend %font-page-section-name;
  width: 340px;
}

@media (max-width: 1750px) {
  .subTitle {
    width: 310px;
  }
}

@media (max-width: 1540px) {
  .subTitle {
    width: 275px;
  }
}

@media (max-width: 1285px) {
  .subTitle {
    width: 245px;
  }
}

@media (max-width: 1100px) {
  .subTitle {
    width: 815px;
  }
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

.wrap {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1100px) {
  .wrap {
    display: none;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .titleWrapper {
    flex-direction: column;
    gap: 30px;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoWrapper h1 {
  @extend %font-page-section-title;
}

.titleWrapper p {
  color: $text-body1;
  font-size: 20px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 660px;
}

@media (max-width: 1750px) {
  .titleWrapper p {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  .titleWrapper p {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  .titleWrapper p {
    font-size: 14px;
  }
}

.mobileLinkWrapper {
  display: none;
}

@media (max-width: 1100px) {
  .mobileLinkWrapper {
    display: block;
    width: fit-content;
    align-items: start;
  }
}

.teamWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.teamWrapper div {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  filter: grayscale(100%);
  transition: 0.3s all;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media (max-width: 1750px) {
  .teamWrapper div {
    width: 280px;
    height: 280px;
  }
}

@media (max-width: 1720px) {
  .teamWrapper div {
    width: 245px;
    height: 245px;
  }
}

@media (max-width: 1610px) {
  .teamWrapper div {
    width: 235px;
    height: 235px;
  }
}

@media (max-width: 1540px) {
  .teamWrapper div {
    width: 230px;
    height: 230px;
  }
}

@media (max-width: 1490px) {
  .teamWrapper div {
    width: 215px;
    height: 215px;
  }
}

@media (max-width: 1420px) {
  .teamWrapper div {
    width: 205px;
    height: 205px;
  }
}

@media (max-width: 1285px) {
  .teamWrapper div {
    width: 180px;
    height: 180px;
  }
}

.teamWrapper div:hover {
  width: 560px;
  height: 560px;
  border-radius: 10px;
  background-repeat: no-repeat;
  filter: none;
}

@media (max-width: 1750px) {
  .teamWrapper div:hover {
    width: 460px;
    height: 460px;
  }
}

@media (max-width: 1720px) {
  .teamWrapper div:hover {
    width: 430px;
    height: 430px;
  }
}

@media (max-width: 1610px) {
  .teamWrapper div:hover {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 1540px) {
  .teamWrapper div:hover {
    width: 380px;
    height: 380px;
  }
}

@media (max-width: 1490px) {
  .teamWrapper div:hover {
    width: 345px;
    height: 345px;
  }
}

@media (max-width: 1420px) {
  .teamWrapper div:hover {
    width: 320px;
    height: 320px;
  }
}

@media (max-width: 1366px) {
  .teamWrapper div:hover {
    width: 310px;
    height: 310px;
  }
}

@media (max-width: 1285px) {
  .teamWrapper div:hover {
    width: 300px;
    height: 300px;
  }
}

.team1 {
  background-image: url('../../../../shared/assets/img/About-company/about-team-1.png');
}

.team2 {
  background-image: url('../../../../shared/assets/img/About-company/about-team-2.jpg');
}

.team3 {
  background-image: url('../../../../shared/assets/img/About-company/about-team-3.png');
}

.team4 {
  background-image: url('../../../../shared/assets/img/About-company/about-team-44.jpg');
}

.teamWrapper p {
  color: $text-dark;
  font-size: 20px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  max-width: 300px;
}

@media (max-width: 1750px) {
  .teamWrapper p {
    font-size: 18px;
    max-width: 280px;
  }
}

@media (max-width: 1540px) {
  .teamWrapper p {
    font-size: 16px;
    max-width: 230px;
  }
}

@media (max-width: 1366px) {
  .teamWrapper p {
    font-size: 15px;
    max-width: 205px;
  }
}

@media (max-width: 1285px) {
  .teamWrapper p {
    font-size: 14px;
    max-width: 180px;
  }
}

.buttonWrapper {
  margin-top: 50px;
  align-self: flex-start;
}

@media (max-width: 1100px) {
  .buttonWrapper {
    display: none;
  }
}

.sliderMobileWrapper {
  display: none;
}

@media (max-width: 1100px) {
  .sliderMobileWrapper {
    display: block;
  }
}

.mobileTeamWrapper img {
  border-radius: 8px;
  width: 98%;
  height: 290px;
  margin-bottom: 10px;
}

.mobileTeamWrapper p {
  color: $text-dark;
  font-family: Evolventa;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
}
