@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1100px) {
  .wrapper {
    margin-bottom: 60px;
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .wrap {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 50px;
  }
}

.tiltleWrapper {
  margin-left: 80px;
}

@media (max-width: 1100px) {
  .tiltleWrapper {
    margin-left: 0;
  }
}

.subTiltleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

.subTitle {
  @extend %font-page-section-name;
  width: 150px;
}

@media (max-width: 1750px) {
  .subTitle {
    width: 130px;
  }
}

@media (max-width: 1285px) {
  .subTitle {
    width: 100px;
  }
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

@media (max-width: 1366px) {
  .subTitleLine {
    width: 300px;
  }
}

@media (max-width: 1100px) {
  .subTitleLine {
    width: 235px;
  }
}

.description {
  @extend %font-page-section-text;
  max-width: 660px;
}

@media (max-width: 1285px) {
  .description {
    max-width: 430px;
  }
}

.linkWrapper {
  display: flex;
  align-self: center;
  margin-right: 80px;
}

@media (max-width: 1750px) {
  .linkWrapper {
    margin-right: 70px;
  }
}

@media (max-width: 1540px) {
  .linkWrapper {
    margin-right: 65px;
  }
}

@media (max-width: 1285px) {
  .linkWrapper {
    margin-right: 60px;
  }
}

@media (max-width: 1100px) {
  .linkWrapper {
    margin-right: 0px;
    align-self: flex-start;
  }
}

.ourProjectsWrapper {
  display: flex;
  gap: 30px;
}

@media (max-width: 1100px) {
  .ourProjectsWrapper {
    flex-direction: column;
  }
}

.projectsWrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  height: fit-content;
}

@media (max-width: 1100px) {
  .projectsWrapper {
    background-color: $bg;
    padding: 0;
  }
}

.projects {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.buttonsWrapper {
  display: flex;
  gap: 80px;
}

@media (max-width: 1750px) {
  .projects {
    margin-bottom: 35px;
  }
}

@media (max-width: 1540px) {
  .projects {
    margin-bottom: 30px;
  }
  .buttonsWrapper {
    gap: 40px;
  }
}

@media (max-width: 1285px) {
  .projects {
    margin-bottom: 20px;
  }
}

@media (max-width: 1100px) {
  .projects {
    margin-bottom: 30px;
    padding-bottom: 10px;
  }
  .buttonsWrapper {
    overflow-x: scroll;
    gap: 20px;
  }
}

.projectInfo {
  display: flex;
  gap: 42px;
  padding: 10px;
  background-color: $violet;
  border-radius: 20px;
}

@media (max-width: 1750px) {
  .projectInfo {
    gap: 37px;
  }
}

@media (max-width: 1540px) {
  .projectInfo {
    gap: 33px;
  }
}

@media (max-width: 1285px) {
  .projectInfo {
    gap: 28px;
  }
}

@media (max-width: 1100px) {
  .projectInfo {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 1100px) {
  .imgWrapper {
    width: 100%;
  }
}

.machineImg {
  border-radius: 10px;
  width: 436px;
  background-color: #fff;
}

@media (max-width: 1750px) {
  .machineImg {
    width: 400px;
  }
}

@media (max-width: 1540px) {
  .machineImg {
    width: 350px;
  }
}

@media (max-width: 1366px) {
  .machineImg {
    width: 350px;
  }
}

@media (max-width: 1285px) {
  .machineImg {
    width: 320px;
  }
}

@media (max-width: 1100px) {
  .machineImg {
    width: 100%;
    height: 260px;
  }
}

.projectInfoTextWrapper {
  max-width: 660px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1750px) {
  .projectInfoTextWrapper {
    margin-top: 18px;
    margin-bottom: 18px;
  }
}

@media (max-width: 1540px) {
  .projectInfoTextWrapper {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1285px) {
  .projectInfoTextWrapper {
    font-size: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.projectInfoTextWrapper h3 {
  color: #fff;
  font-size: 33px;
  font-family: Evolventa;
  line-height: 120%;
  font-weight: 400;
  margin-bottom: 20px;
}

@media (max-width: 1750px) {
  .projectInfoTextWrapper h3 {
    font-size: 30px;
    margin-bottom: 18px;
  }
}

@media (max-width: 1540px) {
  .projectInfoTextWrapper h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1366px) {
  .projectInfoTextWrapper h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1285px) {
  .projectInfoTextWrapper h3 {
    font-size: 20px;
    margin-bottom: 12px;
  }
}

.projectInfoTextWrapper p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-family: Evolventa;
  line-height: 150%;
  margin-bottom: 50px;
}

@media (max-width: 1750px) {
  .projectInfoTextWrapper p {
    font-size: 18px;
    margin-bottom: 43px;
  }
}

@media (max-width: 1540px) {
  .projectInfoTextWrapper p {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1285px) {
  .projectInfoTextWrapper p {
    font-size: 13px;
    margin-bottom: 23px;
  }
}

@media (max-width: 1100px) {
  .projectInfoTextWrapper p {
    font-size: 12px;
    margin-bottom: 20px;
  }
}

.projectInfoTextWrapper a {
  max-width: fit-content;
  color: $text-dark !important;
}

.ourApproachWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px;
  background-color: #fff;
  border-radius: 20px;
  max-width: 500px;
  height: fit-content;
}

@media (max-width: 1540px) {
  .ourApproachWrapper {
    padding: 30px;
  }
}

@media (max-width: 1285px) {
  .ourApproachWrapper {
    padding: 20px;
  }
}

@media (max-width: 1100px) {
  .ourApproachWrapper {
    padding: 20px 16px;
  }
}

.ourApproachTitle {
  color: $violet;
  font-size: 40px;
  font-family: Evolventa;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 30px;
  align-self: center;
}

@media (max-width: 1750px) {
  .ourApproachTitle {
    font-size: 30px;
  }
}

@media (max-width: 1540px) {
  .ourApproachTitle {
    font-size: 25px;
  }
}

@media (max-width: 1285px) {
  .ourApproachTitle {
    font-size: 20px;
  }
}

.number {
  font-family: Evolventa;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background: $green;
  margin-bottom: 20px;
}

@media (max-width: 1750px) {
  .number {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 1285px) {
  .number {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 1100px) {
  .number {
    font-size: 20px;
    width: 42px;
    height: 42px;
  }
}

.ourApproachSubTitle {
  color: $text-dark;
  text-align: center;
  font-weight: 400;
  font-size: 24.5px;
  font-family: Evolventa;
  line-height: 120%;
  margin-bottom: 20px;
}

@media (max-width: 1750px) {
  .ourApproachSubTitle {
    font-size: 21.5px;
  }
}

@media (max-width: 1540px) {
  .ourApproachSubTitle {
    font-size: 19px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1285px) {
  .ourApproachSubTitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1100px) {
  .ourApproachSubTitle {
    font-size: 18px;
  }
}

.ourApproachTetx {
  color: #2f2e2f66;
  font-size: 18px;
  font-family: Evolventa;
  line-height: 130%;
}

@media (max-width: 1750px) {
  .ourApproachTetx {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  .ourApproachTetx {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  .ourApproachTetx {
    font-size: 13px;
  }
}

@media (max-width: 1100px) {
  .ourApproachTetx {
    font-size: 12px;
  }
}

.ourApproachWrapper img {
  width: 40px;
  height: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 1540px) {
  .ourApproachWrapper img {
    width: 35px;
    height: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1285px) {
  .ourApproachWrapper img {
    width: 30px;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.preloadedImages {
  visibility: hidden;
}

.preloadedImage {
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
}
