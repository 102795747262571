@import 'shared/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
}

.input {
  border: none;
  background-color: $bg;
  font-size: 24px;
  line-height: 150%;
  font-family: Evolventa;
  color: $text-dark;
  border-bottom: 1px solid rgba(1, 73, 237, 0.4);
  max-width: 620px;
  width: 100%;
  padding-bottom: 7px;
  outline: none;
  overflow: hidden;
}

@media (max-width: 1750px) {
  .input {
    font-size: 22px;
  }
}

@media (max-width: 1540px) {
  .input {
    font-size: 20px;
    padding-bottom: 8px;
  }
}

@media (max-width: 1285px) {
  .input {
    font-size: 14px;
    padding-bottom: 5px;
  }
}

.inputError {
  border-bottom: 1px solid $red;
}

.resizeNone {
  resize: none;
}

.error {
  color: $red;
  font-family: Evolventa;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

@media (max-width: 1750px) {
  .error {
    font-size: 12px;
  }
}

@media (max-width: 1540px) {
  .error {
    font-size: 10px;
  }
}
