@import 'shared/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1930px) {
  .wrapper {
    padding: 0;
    width: 100%;
  }
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

@media (max-width: 1540px) {
  .footerWrapper {
    margin-bottom: 50px;
  }
}

@media (max-width: 1285px) {
  .footerWrapper {
    margin-bottom: 25px;
  }
}

@media (max-width: 1100px) {
  .footerWrapper {
    flex-direction: column;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 1100px) {
  .column {
    gap: 20px;
  }
}

.title {
  display: flex;
  gap: 5px;
}

@media (max-width: 1100px) {
  .title {
    align-self: flex-start;
    margin-bottom: 10px;
  }
}

.title img {
  width: 177px;
  height: 28px;
}

@media (max-width: 1750px) {
  .title img {
    width: 160px;
    height: 25px;
  }
}

@media (max-width: 1540px) {
  .title img {
    width: 140px;
    height: 22px;
  }
}

@media (max-width: 1285px) {
  .title img {
    width: 120px;
    height: 19px;
  }
}

@media (max-width: 1100px) {
  .title img {
    width: 126.5px;
    height: 20px;
  }
}

.titleBold {
  font-family: 'Fahkwang', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: $violet;
  line-height: 39px;
}

.titleLight {
  font-family: 'Fahkwang', sans-serif;
  font-weight: 300;
  font-size: 30px;
  color: #2f2e2fb2;
  line-height: 39px;
}

@media (max-width: 1750px) {
  .titleBold,
  .titleLight {
    font-size: 25px;
  }
}

@media (max-width: 1540px) {
  .titleBold,
  .titleLight {
    font-size: 23px;
  }
}

@media (max-width: 1285px) {
  .titleBold,
  .titleLight {
    font-size: 16px;
  }
}

@media (max-width: 1100px) {
  .titleBold,
  .titleLight {
    font-size: 19px;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1100px) {
  .infoWrapper {
    gap: 15px;
  }
}

.wrapperMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1100px) {
  .wrapperMobile {
    flex-direction: row;
  }
}

.infoLink {
  color: $violet;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-decoration: none;
}

@media (max-width: 1285px) {
  .infoLink {
    font-size: 14px;
  }
}

.infoLink:hover {
  opacity: 0.8;
}

.infoLink:active {
  opacity: 0.5;
}

.socialMobileWrapper {
  display: none;
}

@media (max-width: 1100px) {
  .socialMobileWrapper {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
  }
}

.chapterTitle {
  cursor: pointer;
  color: $text-dark;
  font-family: Evolventa;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  margin-top: 15px;
}

@media (max-width: 1540px) {
  .chapterTitle {
    font-size: 18px;
  }
}

@media (max-width: 1285px) {
  .chapterTitle {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  .chapterTitle {
    font-size: 16px;
    margin-top: 20px;
  }
}

.chapterTitle:hover {
  opacity: 0.8;
}

.chapterTitle:active {
  opacity: 0.5;
}

.chapterWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.chapterLink {
  color: $violet;
  font-family: Evolventa;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 1285px) {
  .chapterLink {
    font-size: 14px;
  }
}

.chapterLink:hover {
  opacity: 0.8;
}

.chapterLink:active {
  opacity: 0.5;
}

.socialWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}

@media (max-width: 1285px) {
  .socialWrapper {
    justify-content: center;
  }
}

.socialBlockWrapper {
  display: flex;
  gap: 10px;
}

@media (max-width: 1285px) {
  .socialBlockWrapper {
    gap: 5px;
  }
}

@media (max-width: 1100px) {
  .socialBlockWrapper {
    display: none;
  }
}

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;
  border: 1px solid $violet;
  border-radius: 50%;
}

@media (max-width: 1750px) {
  .socialLink {
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 1540px) {
  .socialLink {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 1285px) {
  .socialLink {
    width: 40px;
    height: 40px;
  }
}

.socialLink:hover {
  opacity: 0.8;
}

.socialLink:active {
  opacity: 0.5;
}

@media (max-width: 1285px) {
  .socialLink img {
    width: 15px;
    height: 15px;
  }
}

.divider {
  border-bottom: 1px solid #a1acd7;
  margin-bottom: 20px;
}

@media (max-width: 1285px) {
  .divider {
    margin-bottom: 30px;
  }
}

.privacyBlockWrapper {
  display: flex;
  justify-content: space-between;
  color: $violet;
  font-family: Evolventa;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-decoration: none;
}

@media (max-width: 1100px) {
  .privacyBlockWrapper {
    flex-direction: column;
    gap: 20px;
  }
}

.privacyWrapper {
  display: flex;
  gap: 30px;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .privacyWrapper {
    margin-bottom: 30px;
  }
}

.alignCenter {
  align-self: flex-start;
}
