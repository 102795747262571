@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 100px 16px 0 0;
}

.subTitleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .subTitleWrapper {
    margin-bottom: 30px;
  }

  .wrapper {
    margin: 100px 16px 0 16px;
  }
}

.subTitle {
  @extend %font-page-section-name;
  width: 330px;
}

@media (max-width: 1750px) {
  .subTitle {
    width: 300px;
  }
}

@media (max-width: 1285px) {
  .subTitle {
    width: 235px;
  }
}

@media (max-width: 1100px) {
  .subTitle {
    width: 665px;
  }
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

.title {
  @extend %font-page-section-title;
  margin-bottom: 100px;
}

@media (max-width: 1100px) {
  .title {
    margin-bottom: 30px;
  }
}

.sliderWrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1540px) {
  .sliderWrapper {
    gap: 30px;
  }
}

@media (max-width: 1100px) {
  .sliderWrapper {
    display: none;
  }
}

.slideButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.slideButtons button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 57px;
  height: 57px;
  border: 1px solid $violet;
  border-radius: 50%;
  background-color: $bg;
}

.slideButtons button:hover {
  opacity: 0.8;
}

.slideButtons button:active {
  opacity: 0.5;
}

.slideButtons button img {
  width: 25px;
  height: 25px;
}

@media (max-width: 1750px) {
  .slideButtons button {
    width: 50px;
    height: 50px;
  }
  .slideButtons button img {
    width: 23px;
    height: 23px;
  }
}

@media (max-width: 1540px) {
  .slideButtons button {
    width: 45px;
    height: 45px;
  }
  .slideButtons button img {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1285px) {
  .slideButtons button {
    width: 40px;
    height: 40px;
  }
  .slideButtons button img {
    width: 16px;
    height: 16px;
  }
}

.sliderMobileWrapper {
  display: none;
}

@media (max-width: 1100px) {
  .sliderMobileWrapper {
    display: block;
  }
}

.productWrapper {
  display: flex !important;
  flex-direction: column;
  gap: 30px;
  min-width: 100%;
  max-width: 470px;
  cursor: pointer !important;
  text-decoration: none;
}

@media (max-width: 1750px) {
  .productWrapper {
    max-width: 430px;
    gap: 25px;
  }
}

@media (max-width: 1540px) {
  .productWrapper {
    max-width: 380px;
    gap: 20px;
  }
}

@media (max-width: 1285px) {
  .productWrapper {
    max-width: 330px;
    gap: 15px;
  }
}

.productWrapper img {
  width: 470px;
  height: 470px;
  border-radius: 14px;
}

@media (max-width: 1750px) {
  .productWrapper img {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 1540px) {
  .productWrapper img {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 1285px) {
  .productWrapper img {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 1100px) {
  .productWrapper img {
    width: 96%;
    height: 288px;
  }
}

.productWrapper p {
  color: $text-dark;
  font-size: 30px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  // width: 96%;
  max-width: 470px;
}

@media (max-width: 1750px) {
  .productWrapper p {
    font-size: 28px;
    max-width: 400px;
  }
}

@media (max-width: 1540px) {
  .productWrapper p {
    font-size: 26px;
    max-width: 350px;
  }
}

@media (max-width: 1285px) {
  .productWrapper p {
    font-size: 22px;
    max-width: 300px;
  }
}

@media (max-width: 1100px) {
  .productWrapper p {
    max-width: 96%;
    width: 100%;
  }
}

.productWrapper p:hover {
  opacity: 0.5;
}

.productWrapper p:active {
  opacity: 0.8;
}

.cursorDefault {
  cursor: default;
}

.cursorGrab {
  cursor: grab;
}

.cursorGrab:active {
  cursor: grabbing;
}

.slider div div {
  display: flex;
  gap: 30px;
}

.slider div div::before {
  display: none;
}

.slider div div div {
  width: fit-content !important;
}

.center div div::before {
  display: flex;
  justify-content: center;
}
