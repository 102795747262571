@import 'shared/styles/colors';

.buttonLight {
  display: flex;
  align-self: center;
  align-items: center;
  gap: 17px;

  font-family: Evolventa;
  font-size: 20px;
  text-decoration: none;
  padding: 15px 25px;
  background-color: #fff;
  color: $violet;
  border-radius: 14px;
  border: 1px solid #3c29a3;
}

.buttonGreen {
  display: flex;
  align-self: center;
  align-items: center;
  gap: 17px;

  font-family: Evolventa;
  font-size: 20px;
  text-decoration: none;
  padding: 15px 25px;
  background-color: #bbf023;
  color: $text-dark;
  border-radius: 14px;
}

.buttonViolet {
  background-color: $violet;
  color: #fff;
}

@media (max-width: 1750px) {
  .buttonGreen,
  .buttonLight {
    font-size: 18px;
    padding: 13px 22px;
  }
}

@media (max-width: 1540px) {
  .buttonGreen,
  .buttonLight {
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 12px;
  }
}

@media (max-width: 1285px) {
  .buttonGreen,
  .buttonLight {
    font-size: 13px;
    padding: 6px 14px;
    border-radius: 10px;
  }
}

.buttonDark:hover,
.buttonGreen:hover {
  opacity: 0.8;
}

.buttonDark:active,
.buttonGreen:active {
  opacity: 0.5;
}

.small {
  padding: 10px 14px 10px 20px;
  color: $text-dark;
  border: none;
}

@media (max-width: 1750px) {
  .small {
    padding: 9px 12px 9px 18px;
  }
}

@media (max-width: 1540px) {
  .small {
    padding: 7px 11px 7px 16px;
  }
}

@media (max-width: 1285px) {
  .small {
    padding: 5px 10px 5px 14px;
  }
}
