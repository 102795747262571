@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.title {
  @extend %font-page-section-title;
}

.whoAreWeWrapper {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1100px) {
  .whoAreWeWrapper {
    flex-direction: column;
  }
}

.mobileDivider {
  display: none;
}

@media (max-width: 1100px) {
  .mobileDivider {
    display: block;
    border: 0.1px solid $violet;
    width: 100%;
  }
}

.whoAreWeTextWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  border-left: 1px solid $violet;
  max-width: 550px;
  height: fit-content;
  gap: 30px;
}

@media (max-width: 1750px) {
  .whoAreWeTextWrapper {
    max-width: 450px;
    gap: 25px;
  }
}

@media (max-width: 1540px) {
  .whoAreWeTextWrapper {
    max-width: 400px;
    gap: 20px;
  }
}

@media (max-width: 1366px) {
  .whoAreWeTextWrapper {
    max-width: 375px;
  }
}

@media (max-width: 1285px) {
  .whoAreWeTextWrapper {
    max-width: 350px;
  }
}

@media (max-width: 1100px) {
  .whoAreWeTextWrapper {
    padding-left: 0;
    border-left: none;
    margin-top: 20px;
  }
}

.whoAreWeTextWrapper h3 {
  color: $text-dark;
  font-size: 40px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 1750px) {
  .whoAreWeTextWrapper h3 {
    font-size: 37px;
  }
}

@media (max-width: 1540px) {
  .whoAreWeTextWrapper h3 {
    font-size: 33px;
  }
}

@media (max-width: 1366px) {
  .whoAreWeTextWrapper h3 {
    font-size: 30px;
  }
}

@media (max-width: 1285px) {
  .whoAreWeTextWrapper h3 {
    font-size: 28px;
  }
}

@media (max-width: 1100px) {
  .whoAreWeTextWrapper h3 {
    font-size: 30px;
  }
}

.whoAreWeTextWrapper p {
  color: $text-dark;
  font-size: 20px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media (max-width: 1750px) {
  .whoAreWeTextWrapper p {
    font-size: 17px;
  }
}

@media (max-width: 1540px) {
  .whoAreWeTextWrapper p {
    font-size: 15px;
  }
}

@media (max-width: 1285px) {
  .whoAreWeTextWrapper p {
    font-size: 13px;
  }
}

@media (max-width: 1100px) {
  .whoAreWeTextWrapper p {
    font-size: 12px;
    margin-bottom: 30px;
  }
}

.whoAreWeWrapper img {
  width: 55%;
  height: 20%;
  border-radius: 14px;
}

@media (max-width: 1100px) {
  .whoAreWeWrapper img {
    width: 100%;
    height: 200px;
  }
}
