@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1100px) {
  .wrapper {
    gap: 30px;
  }
}

.head {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.titleWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 1100px) {
  .titleWrapper {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
}

.title {
  color: $text-dark;
  font-size: 52px;
  font-family: Evolventa;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;
  max-width: 600px;
}

@media (max-width: 1750px) {
  .title {
    font-size: 48px;
    max-width: 550px;
  }
}

@media (max-width: 1540px) {
  .title {
    font-size: 42px;
    max-width: 480px;
  }
}

@media (max-width: 1366px) {
  .title {
    font-size: 40px;
    max-width: 450px;
  }
}

@media (max-width: 1285px) {
  .title {
    font-size: 32px;
    max-width: 380px;
  }
}

@media (max-width: 1100px) {
  .title {
    word-break: break-word;
  }
}

.subTitle {
  color: $text-body1;
  font-size: 20px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 700px;
  align-self: flex-end;
}

@media (max-width: 1750px) {
  .subTitle {
    font-size: 18px;
    max-width: 580px;
  }
}

@media (max-width: 1540px) {
  .subTitle {
    font-size: 16px;
    max-width: 520px;
  }
}

@media (max-width: 1366px) {
  .subTitle {
    max-width: 400px;
  }
}

@media (max-width: 1285px) {
  .subTitle {
    font-size: 14px;
    max-width: 370px;
  }
}

@media (max-width: 1100px) {
  .subTitle {
    font-size: 14px;
    max-width: 350px;
    align-self: flex-start;
  }
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1100px) {
  .infoWrapper {
    flex-direction: column;
    gap: 50px;
  }
}

.imgBlockWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  max-width: 600px;
  width: 100%;
  margin-bottom: 30px;
}

@media (max-width: 1750px) {
  .imgBlockWrapper {
    max-width: 550px;
  }
}

@media (max-width: 1540px) {
  .imgBlockWrapper {
    max-width: 480px;
  }
}

@media (max-width: 1366px) {
  .imgBlockWrapper {
    max-width: 450px;
  }
}

@media (max-width: 1285px) {
  .imgBlockWrapper {
    gap: 60px;
    max-width: 380px;
  }
}

@media (max-width: 1100px) {
  .imgBlockWrapper {
    width: 100%;
  }
}

.imgBlockWrapper div {
  width: 100%;
}

.imgBlockWrapper a {
  align-self: flex-start;
}

@media (max-width: 1100px) {
  .imgBlockWrapper a {
    align-self: center;
  }
}

.mainImg {
  background-size: cover;
  width: 100%;
  height: 730px;
  border-radius: 20px;
}

@media (max-width: 1750px) {
  .mainImg {
    height: 650px;
  }
}

@media (max-width: 1540px) {
  .mainImg {
    height: 560px;
  }
}

@media (max-width: 1366px) {
  .mainImg {
    height: 500px;
  }
}

@media (max-width: 1285px) {
  .mainImg {
    height: 450px;
  }
}

@media (max-width: 1100px) {
  .mainImg {
    width: 100%;
    height: 400px;
  }
}

.chooseBgWrapper {
  display: flex;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 10px;
  gap: 30px;
}

@media (max-width: 1750px) {
  .chooseBgWrapper {
    gap: 30px;
  }
}

@media (max-width: 1540px) {
  .chooseBgWrapper {
    gap: 20px;
  }
}

@media (max-width: 1285px) {
  .chooseBgWrapper {
    gap: 15px;
  }
}

@media (max-width: 1100px) {
  .chooseBgWrapper {
    justify-content: center;
    margin-bottom: 0;
  }
}

.chooseBgWrapper button {
  width: 80px;
  height: 80px;
  border: none;
  cursor: pointer;
}
.chooseBgWrapper button img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.active {
  border: 5px solid $green;
}

@media (max-width: 1750px) {
  .chooseBgWrapper button,
  .chooseBgWrapper button img {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 1285px) {
  .chooseBgWrapper button,
  .chooseBgWrapper button img {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 1100px) {
  .chooseBgWrapper button,
  .chooseBgWrapper button img {
    width: 40px;
    height: 40px;
  }
}

.chooseBgWrapper button:hover {
  opacity: 0.8;
}

.chooseBgWrapper button:active {
  opacity: 0.5;
}

.textBlockWrapper {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}

@media (max-width: 1750px) {
  .textBlockWrapper {
    max-width: 580px;
  }
}

@media (max-width: 1540px) {
  .textBlockWrapper {
    max-width: 520px;
  }
}

@media (max-width: 1366px) {
  .textBlockWrapper {
    max-width: 400px;
  }
}

@media (max-width: 1285px) {
  .textBlockWrapper {
    max-width: 370px;
  }
}

.infoTitle {
  color: $violet;
  font-size: 40px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 20px;
}

@media (max-width: 1750px) {
  .infoTitle {
    font-size: 34px;
  }
}

@media (max-width: 1540px) {
  .infoTitle {
    font-size: 30px;
  }
}

@media (max-width: 1285px) {
  .infoTitle {
    font-size: 26px;
  }
}

.description,
.description p {
  color: $text-dark;
  font-size: 18px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  list-style-position: inside;
}

@media (max-width: 1750px) {
  .description,
  .description p,
  .description ul li {
    font-size: 16px;
  }
}

@media (max-width: 1540px) {
  .description,
  .description p,
  .description ul li {
    font-size: 15px;
  }
}

@media (max-width: 1285px) {
  .description,
  .description p,
  .description ul li {
    font-size: 13px;
  }
}

@media (max-width: 1100px) {
  .description,
  .description p,
  .description ul li {
    font-size: 12px;
  }
}

.divider {
  margin: 50px 0;
  border-bottom: 1px solid #a1acd7;
}

@media (max-width: 1750px) {
  .divider {
    margin: 40px 0;
  }
}

@media (max-width: 1540px) {
  .divider {
    margin: 35px 0;
  }
}

@media (max-width: 1285px) {
  .divider {
    margin: 25px 0;
  }
}
