@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1930px) {
  .container {
    padding: 0;
    max-width: 1520px;
  }
}

.breadCrumbsWrapper {
  margin-bottom: 50px;
}

.title {
  @extend %font-page-section-title;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .title {
    margin-bottom: 50px;
  }
}

.mapWrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 50px;
}

@media (max-width: 1100px) {
  .mapWrapper {
    gap: 30px;
  }
}

.map {
  width: 100%;
  height: 700px;
  border-radius: 20px;
  border: 1px solid $violet;
}

@media (max-width: 1750px) {
  .map {
    height: 620px;
  }
}

@media (max-width: 1540px) {
  .map {
    height: 550px;
  }
}

@media (max-width: 1285px) {
  .map {
    height: 460px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
}

@media (max-width: 1100px) {
  .wrapper {
    flex-direction: column;
    gap: 10px;
  }
}

.contactsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 180px;
  gap: 30px;
  padding: 35px;
  border: 1px solid $violet;
  border-radius: 10px;
  background-color: #fff;
}

@media (max-width: 1750px) {
  .contactsWrapper {
    padding: 25px;
    height: 150px;
  }
}

@media (max-width: 1540px) {
  .contactsWrapper {
    height: 140px;
  }
}

@media (max-width: 1285px) {
  .contactsWrapper {
    padding: 20px;
    gap: 20px;
    height: 130px;
  }
}

@media (max-width: 1100px) {
  .contactsWrapper {
    padding: 16px;
    gap: 10px;
    height: auto;
    border-radius: 12px;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  border-left: 2px solid $violet;
  padding-left: 15px;
  gap: 10px;
}

@media (max-width: 1100px) {
  .contacts {
    border-left: 1px solid $violet;
  }
}

.contacts p {
  color: $text-dark;
  font-size: 24px;
  font-family: Evolventa;
  line-height: 130%;
}

.contacts a {
  color: $text-dark;
  font-size: 24px;
  font-family: Evolventa;
  line-height: 130%;
  cursor: pointer !important;
  text-decoration: none;
}

@media (max-width: 1750px) {
  .contacts a,
  .contacts p {
    font-size: 22px;
  }
}

@media (max-width: 1540px) {
  .contacts a,
  .contacts p {
    font-size: 20px;
  }
}

@media (max-width: 1285px) {
  .contacts a,
  .contacts p {
    font-size: 18px;
  }
}

@media (max-width: 1100px) {
  .contacts a,
  .contacts p {
    font-size: 14px;
  }
}

.contactsTitle {
  color: $violet;
  font-size: 30px;
  font-family: Evolventa;
  line-height: 120%;
}

@media (max-width: 1750px) {
  .contactsTitle {
    font-size: 26px;
  }
}

@media (max-width: 1540px) {
  .contactsTitle {
    font-size: 24px;
  }
}

@media (max-width: 1285px) {
  .contactsTitle {
    font-size: 22px;
  }
}

@media (max-width: 1100px) {
  .contactsTitle {
    font-size: 16px;
    font-weight: 400;
  }
}
