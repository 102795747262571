@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  color: $text-dark;
  font-size: 26px;
  line-height: 120%;
  max-width: 175px;
  min-width: 125px;
  gap: 30px;
  padding: 20px;
  cursor: pointer;
}

@media (max-width: 1540px) {
  .wrapper {
    gap: 15px;
  }
}

@media (max-width: 1100px) {
  .wrapper {
    padding: 10px;
    gap: 10px;
    max-width: 100px;
  }
}

.wrapper p {
  @extend %font-page-section-text;
  color: $text-dark;
  cursor: pointer;
  font-size: 18px;
}

@media (max-width: 1100px) {
  .wrapper p {
    max-width: 80px;
    font-size: 14px;
  }
}

.wrapper:hover {
  opacity: 0.8;
}

.wrapper:active {
  opacity: 0.5;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background: #f7f6fb;
}

@media (max-width: 1540px) {
  .number {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 1366px) {
  .number {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 1100px) {
  .number {
    width: 40px;
    height: 40px;
  }
}

.number p {
  cursor: pointer;
}

.wrapperActive {
  display: flex;
  flex-direction: column;
  color: $text-dark;
  background-color: #f7f6fb;
  font-weight: 700;
  line-height: 120%;
  max-width: 250px;
  min-width: 125px;
  gap: 30px;
  padding: 20px;
  border-radius: 14px;
  cursor: pointer;
}

@media (max-width: 1540px) {
  .wrapperActive {
    gap: 15px;
  }
}

@media (max-width: 1285px) {
  .wrapperActive,
  .wrapper {
    padding: 10px;
  }
}

@media (max-width: 1100px) {
  .wrapperActive,
  .wrapper {
    word-wrap: break-word;
    max-width: 100px;
    gap: 10px;
  }
}

.wrapperActive p {
  font-size: 20px;
  font-family: EvolventaBold;
  cursor: pointer;
  font-weight: 700;
  line-height: 130%;
}

@media (max-width: 1750px) {
  .wrapperActive p,
  .wrapper p {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  .wrapperActive p,
  .wrapper p {
    font-size: 15px;
  }
}

@media (max-width: 1366px) {
  .wrapperActive p,
  .wrapper p {
    font-size: 15px;
  }
}

@media (max-width: 1285px) {
  .wrapperActive p,
  .number p,
  .wrapper p {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  .wrapperActive p,
  .wrapper p,
  .number p {
    font-size: 14px;
  }
}

.wrapperActive:hover {
  opacity: 0.8;
}

.wrapperActive:active {
  opacity: 0.5;
}

.numberActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background: $green;
}

@media (max-width: 1540px) {
  .numberActive {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 1366px) {
  .numberActive {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 1100px) {
  .numberActive {
    width: 40px;
    height: 40px;
  }
}

.numberActive p {
  cursor: pointer;
}
