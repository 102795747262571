@import 'shared/styles/colors';

.category {
  border: 1px solid $text-body1;
  border-radius: 14px;
  padding: 10px 20px;
  color: $text-body1;
  font-size: 24px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  background-color: $bg;
}

@media (max-width: 1750px) {
  .category {
    font-size: 18px;
    padding: 8px 18px;
  }
}

@media (max-width: 1540px) {
  .category {
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 10px;
  }
}

@media (max-width: 1285px) {
  .category {
    font-size: 14px;
  }
}

.category:hover {
  opacity: 0.8;
}

.category:active {
  opacity: 0.5;
}

.colorActive {
  border: 1px solid $green;
  background-color: $green;
  color: $text-dark;
}
