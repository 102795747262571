@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1930px) {
  .wrapper {
    margin: 0 auto;
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .wrapper {
    flex-direction: column;
  }
}

.imgMobile {
  display: none;
}

@media (max-width: 1100px) {
  .imgMobile {
    display: flex;
    align-self: flex-end;
    width: 175px;
    height: 150px;
    background-size: contain;
    margin-top: 10px;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1285px) {
  .textWrapper {
    max-width: 450px;
  }
}

.subTiltleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.subTitle {
  @extend %font-page-section-name;
  width: 205px;
}

@media (max-width: 1750px) {
  .subTitle {
    width: 195px;
  }
}

@media (max-width: 1540px) {
  .subTitle {
    width: 170px;
  }
}

@media (max-width: 1285px) {
  .subTitle {
    width: 240px;
  }
}

@media (max-width: 1100px) {
  .subTitle {
    width: 270px;
  }
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

@media (max-width: 1366px) {
  .subTitleLine {
    width: 380px;
  }
}

.title {
  @extend %font-page-section-title;
  max-width: 600px;
  margin-bottom: 40px;
}

.text {
  @extend %font-page-section-text;
  max-width: 600px;
  margin-bottom: 20px;
}

@media (max-width: 1750px) {
  .title,
  .text {
    max-width: 550px;
  }
}

@media (max-width: 1540px) {
  .title,
  .text {
    max-width: 450px;
  }

  .title {
    margin-bottom: 30px;
  }
}

@media (max-width: 1285px) {
  .title,
  .text {
    max-width: 380px;
  }
}

.img {
  width: 385px;
  height: 330px;
}

@media (max-width: 1540px) {
  .img {
    width: 300px;
    height: 250px;
  }
}

@media (max-width: 1285px) {
  .img {
    width: 250px;
    height: 200px;
  }
}

@media (max-width: 1100px) {
  .img {
    display: none;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 620px;
  width: 100%;
  padding-top: 100px;
}

@media (min-width: 1930px) {
  .formWrapper {
    width: 620px;
  }
}

@media (max-width: 1750px) {
  .formWrapper {
    width: 600px;
  }
}

@media (max-width: 1540px) {
  .formWrapper {
    max-width: 500px;
    gap: 35px;
  }
}

@media (max-width: 1285px) {
  .formWrapper {
    padding-top: 85px;
    max-width: 350px;
  }
}

@media (max-width: 1100px) {
  .formWrapper {
    padding-top: 50px;
    max-width: 100%;
  }
}

.button {
  display: flex;
  align-self: left;
  align-items: center;
  gap: 17px;

  width: fit-content;
  font-family: Evolventa;
  font-size: 20px;
  text-decoration: none;
  padding: 15px 25px;
  background-color: #bbf023;
  color: $text-dark;
  border-radius: 14px;
  border: none;
  cursor: pointer;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  opacity: 0.5;
}

.button img {
  width: 25px;
  height: 25px;
}

@media (max-width: 1540px) {
  .button {
    border-radius: 10px;
    font-size: 16px;
    padding: 12px 18px;
  }
  .button img {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1285px) {
  .button {
    font-size: 13px;
    padding: 10px 14px;
  }

  .button img {
    width: 16px;
    height: 16px;
  }
}
