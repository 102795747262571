@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
}

.subTiltleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

.subTitle {
  @extend %font-page-section-name;
  width: 340px;
}

@media (max-width: 1750px) {
  .subTitle {
    width: 308px;
  }
}

@media (max-width: 1540px) {
  .subTitle {
    width: 277px;
  }
}

@media (max-width: 1285px) {
  .subTitle {
    width: 244px;
  }
}

@media (max-width: 1100px) {
  .subTitle {
    width: 851px;
  }
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

.title {
  @extend %font-page-section-title;
  margin-bottom: 100px;
}

@media (max-width: 1100px) {
  .title {
    margin-bottom: 30px;
  }
}

.colorViolet {
  color: $violet;
}

.colorDark {
  color: $text-dark;
}

.maxWidth {
  max-width: 700px;
}

.textWrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 1100px) {
  .textWrapper {
    flex-direction: column;
    gap: 10px;
  }
}

.text {
  font-size: 26px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 100%;
}

@media (max-width: 1750px) {
  .text {
    font-size: 24px;
  }
}

@media (max-width: 1540px) {
  .text {
    font-size: 22px;
  }
}

@media (max-width: 1285px) {
  .text {
    font-size: 20px;
  }
}

.divider {
  margin: 30px 0;
  border-bottom: 1px solid #a1acd7;
}

@media (max-width: 1285px) {
  .divider {
    margin: 20px 0;
  }
}
