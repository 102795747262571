@import 'shared/styles/colors';

@font-face {
  font-family: 'Evolventa';
  src: url('./shared/fonts/Evolventa-Regular.ttf');
}

@font-face {
  font-family: 'EvolventaBold';
  src: url('./shared/fonts/Evolventa-Bold.ttf');
}

@font-face {
  font-family: 'TTCommons';
  src: url('./shared/fonts/TTCommons-Thin.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  background: $bg;
  width: 100%;
}

a {
  font-size: 20px;
  font-family: Evolventa;
  color: $violet;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  cursor: default;
}
