@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1100px) {
  .wrapper {
    gap: 30px;
  }
}

.title {
  color: $violet;
  font-size: 40px;
  font-family: Evolventa;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 1750px) {
  .title {
    font-size: 37px;
  }
}

@media (max-width: 1540px) {
  .title {
    font-size: 33px;
  }
}

@media (max-width: 1285px) {
  .title {
    font-size: 28px;
  }
}

.categoriesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media (max-width: 1100px) {
  .categoriesWrapper {
    display: none;
  }
}

.selectCategoryMobile {
  display: none;
}

@media (max-width: 1100px) {
  .selectCategoryMobile {
    display: block;
    border: 1px solid $violet;
    border-radius: 8px;
    padding: 8px 15px;
    font-size: 14px;
    font-family: Evolventa;
    color: $text-dark;
    background: url(../../../../shared/assets/icons/arrows/arrow-bottom-mobile.svg) no-repeat right
      $bg;
    background-position-x: 96%;
    -webkit-appearance: none;
  }
}

.productsWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.85%;
  row-gap: 30px;
}

@media (max-width: 1750px) {
  .productsWrapper {
    row-gap: 25px;
  }
}

@media (max-width: 1540px) {
  .productsWrapper {
    row-gap: 20px;
  }
}

@media (max-width: 1285px) {
  .productsWrapper {
    row-gap: 15px;
  }
}

@media (max-width: 1100px) {
  .productsWrapper {
    gap: 20px;
  }
}

.justifyContent {
  justify-content: flex-start;
}
