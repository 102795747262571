@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  gap: 20px;
  padding: 25px;
  background-color: $bg;
  border-radius: 6px;
  filter: drop-shadow(0px -4px 10px rgba(47, 17, 96, 0.06))
    drop-shadow(0px 10px 20px rgba(47, 17, 96, 0.2));
  opacity: 1 !important;
  width: max-content;
}

@media (max-width: 1750px) {
  .wrapper {
    padding: 23px;
  }
}

@media (max-width: 1540px) {
  .wrapper {
    padding: 20px;
  }
}

@media (max-width: 1285px) {
  .wrapper {
    padding: 18px;
  }
}

.categoryWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: fit-content;
}

.categoryWrapper h6 {
  padding: 8px 15px;
  border: 1px solid $violet;
  border-radius: 50px;
  color: #3c29a3;
  font-family: Evolventa;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  text-transform: uppercase;
}

.categoryWrapper h6:hover {
  opacity: 0.8;
}

.categoryWrapper h6:active {
  opacity: 0.5;
}

@media (max-width: 1750px) {
  .categoryWrapper h6 {
    font-size: 16px;
  }
}

@media (max-width: 1540px) {
  .categoryWrapper h6 {
    font-size: 14px;
    padding: 7px 13px;
  }
}

@media (max-width: 1285px) {
  .categoryWrapper h6 {
    font-size: 12px;
    padding: 6px 12px;
  }
}

.productsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.product {
  display: flex;
  align-items: flex-start;
  gap: 7px;
  text-decoration: none;
  cursor: pointer !important;
  max-width: 340px;
  margin-left: 15px;
}

@media (max-width: 1750px) {
  .product {
    max-width: 310px;
  }
}

@media (max-width: 1540px) {
  .product {
    max-width: 290px;
  }
}

@media (max-width: 1285px) {
  .product {
    max-width: 225px;
  }
}

.product:hover {
  opacity: 0.8;
}

.product:active {
  opacity: 0.5;
}

.product img {
  width: 20px;
  height: 20px;
}

@media (max-width: 1750px) {
  .product img {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 1540px) {
  .product img {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 1285px) {
  .product img {
    width: 14px;
    height: 14px;
  }
}

.product p {
  color: $violet;
  font-family: Evolventa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
}

@media (max-width: 1750px) {
  .product p {
    font-size: 14px;
  }
}

@media (max-width: 1540px) {
  .product p {
    font-size: 12px;
  }
}

@media (max-width: 1285px) {
  .product p {
    font-size: 10px;
  }
}

.product p:hover {
  cursor: pointer !important;
}
