@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 0 50px 200px;
  gap: 30px;
}

@media (min-width: 1930px) {
  .wrapper {
    max-width: 1520px;
    margin: auto;
  }
}

@media (max-width: 1100px) {
  .wrapper {
    margin: 100px 16px 60px 16px;
  }
}

.title {
  color: $violet;
  font-family: Evolventa;
  font-size: 40px;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 1750px) {
  .title {
    font-size: 36px;
  }
}

@media (max-width: 1540px) {
  .title {
    font-size: 32px;
  }
}

@media (max-width: 1285px) {
  .title {
    font-size: 28px;
  }
}

.sliderWrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1540px) {
  .sliderWrapper {
    gap: 30px;
  }
}

@media (max-width: 1100px) {
  .sliderWrapper {
    display: none;
  }
}

.slideButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.slideButtons button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 57px;
  height: 57px;
  border: 1px solid $violet;
  background-color: $bg;
  border-radius: 50%;
}

.slideButtons button:hover {
  opacity: 0.8;
}

.slideButtons button:active {
  opacity: 0.5;
}

.slideButtons button img {
  width: 25px;
  height: 25px;
}

@media (max-width: 1750px) {
  .slideButtons button {
    width: 50px;
    height: 50px;
  }
  .slideButtons button img {
    width: 23px;
    height: 23px;
  }
}

@media (max-width: 1540px) {
  .slideButtons button {
    width: 45px;
    height: 45px;
  }
  .slideButtons button img {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1285px) {
  .slideButtons button {
    width: 40px;
    height: 40px;
  }
  .slideButtons button img {
    width: 16px;
    height: 16px;
  }
}

.sliderMobileWrapper {
  display: none;
}

@media (max-width: 1100px) {
  .sliderMobileWrapper {
    display: block;
  }
}

.productWrapper,
.productMobileWrapper {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

@media (max-width: 1750px) {
  .productWrapper {
    max-width: 430px;
  }
}

@media (max-width: 1540px) {
  .productWrapper {
    max-width: 380px;
  }
}

@media (max-width: 1285px) {
  .productWrapper {
    max-width: 330px;
  }
}

.productWrapper img {
  width: 470px;
  height: 470px;
  border-radius: 14px;
  margin-bottom: 30px;
  cursor: pointer;
}

@media (max-width: 1750px) {
  .productWrapper img {
    width: 400px;
    height: 400px;
    margin-bottom: 25px;
  }
}

@media (max-width: 1540px) {
  .productWrapper img {
    width: 350px;
    height: 350px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1285px) {
  .productWrapper img {
    width: 300px;
    height: 300px;
  }
}

.productMobileWrapper img {
  width: 98%;
  height: 290px;
  margin-bottom: 10px;
  border-radius: 14px;
}

.productWrapper a,
.productMobileWrapper a {
  display: flex;
  color: $text-dark;
  font-size: 30px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
  max-width: 470px;
}

@media (max-width: 1750px) {
  .productWrapper a,
  .productMobileWrapper a {
    font-size: 28px;
    max-width: 400px;
  }
}

@media (max-width: 1540px) {
  .productWrapper a,
  .productMobileWrapper a {
    font-size: 26px;
    max-width: 350px;
  }
}

@media (max-width: 1285px) {
  .productWrapper a,
  .productMobileWrapper a {
    font-size: 22px;
    max-width: 300px;
  }
}

@media (max-width: 1100px) {
  .productMobileWrapper a {
    font-size: 14px;
    max-width: 96%;
    width: 100%;
  }
}

.productWrapper a:hover {
  opacity: 0.5;
}

.productWrapper a:active {
  opacity: 0.8;
}

.cursorDefault {
  cursor: default;
}

.cursorGrab {
  cursor: grab;
}

.cursorGrab:active {
  cursor: grabbing;
}

.slider div div {
  display: flex;
  gap: 30px;
}

.slider div div::before {
  display: none;
}

.slider div div div {
  width: fit-content !important;
}
