@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
}

@media (max-width: 1100px) {
  .wrapper {
    flex-direction: column;
  }
}

.wrapperText {
  display: flex;
  flex-direction: column;
  max-width: 650px;
}

@media (max-width: 1285px) {
  .wrapperText {
    max-width: 500px;
  }
}

.subTiltleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .subTiltleWrapper {
    margin-bottom: 30px;
  }
}

.subTitle {
  @extend %font-page-section-name;
  width: 185px;
}

@media (max-width: 1750px) {
  .subTitle {
    width: 165px;
  }
}

@media (max-width: 1285px) {
  .subTitle {
    width: 170px;
  }
}

@media (max-width: 1100px) {
  .subTitle {
    width: 105px;
  }
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

@media (max-width: 1100px) {
  .subTitleLine {
    width: 233px;
  }
}

.title {
  @extend %font-page-section-title;
  margin-bottom: 40px;
}

.text {
  @extend %font-page-section-text;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .text {
    margin-bottom: 20px;
  }
}

.buttonWrapper {
  align-self: flex-start;
}

.img {
  width: 1040px;
  background-image: url('../../../../shared/assets/img/Generator-vector.png');
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1750px) {
  .img {
    width: 840px;
  }
}

@media (max-width: 1540px) {
  .img {
    width: 670px;
  }
}

@media (max-width: 1285px) {
  .img {
    width: 565px;
  }
}

@media (max-width: 1100px) {
  .img {
    width: 100%;
    height: 220px;
  }
}
