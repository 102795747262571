.wrapper iframe {
  height: 60vh;
  width: 50vw;
}

@media (max-width: 1100px) {
  .wrapper iframe {
    height: 40vh;
    width: 80vw;
  }
}
