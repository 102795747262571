@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.container {
  display: flex;
  flex-direction: column;
  padding: 50px 120px 0px 120px;
  gap: 100px;
}

@media (min-width: 1930px) {
  .container {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 1820px;
  }
}

@media (max-width: 1285px) {
  .container {
    padding: 30px 120px 0px 120px;
    gap: 50px;
  }
}

@media (max-width: 1100px) {
  .container {
    padding: 60px 16px 0px 16px;
    gap: 150px;
  }
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1100px) {
  .headerWrapper {
    gap: 20px;
  }
}

.bgWrapper {
  background-image: url('../../shared/assets/img/equipments.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  height: 630px;

  display: flex;
  flex-direction: column;
  padding: 30px 80px;
  gap: 140px;
}

@media (max-width: 1750px) {
  .bgWrapper {
    height: 590px;
  }
}

@media (max-width: 1540px) {
  .bgWrapper {
    height: 530px;
    gap: 100px;
  }
}

@media (max-width: 1285px) {
  .bgWrapper {
    gap: 80px;
    height: 400px;
  }
}

@media (max-width: 1100px) {
  .bgWrapper {
    background-image: url('../../shared/assets/img/mobile/equipments.png');
    background-size: cover;
    padding: 16px 16px 50px 16px;
    gap: 50px;
    height: auto;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
  max-width: 1056px;
  gap: 30px;
}

@media (max-width: 1750px) {
  .textWrapper {
    max-width: 950px;
  }
}

@media (max-width: 1540px) {
  .textWrapper {
    max-width: 850px;
    gap: 20px;
  }
}

@media (max-width: 1285px) {
  .textWrapper {
    max-width: 690px;
    gap: 15px;
  }
}

@media (max-width: 1100px) {
  .textWrapper {
    gap: 20px;
  }
}

.title {
  @extend %font-page-title;
}

.line {
  border-bottom: 1px solid $subtitle;
  width: 100%;
  align-self: center;
}

.textWrapper p {
  @extend %font-page-text;
  align-self: center;
}

.mobileLink {
  display: none;
}

@media (max-width: 1100px) {
  .mobileLink {
    display: flex;
    align-self: center;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

@media (max-width: 1100px) {
  .wrap {
    gap: 0;
  }
}

.indentation {
  padding: 0 5.5vw;
}

@media (max-width: 1540px) {
  .indentation {
    padding: 0 6vw;
  }
}

@media (max-width: 1366px) {
  .indentation {
    padding: 0 7vw;
  }
}

@media (max-width: 1285px) {
  .indentation {
    padding: 0 6.8vw;
  }
}

@media (max-width: 1100px) {
  .indentation {
    padding: 0;
  }
}

@media (min-width: 1930px) {
  .indentation {
    padding: 0 8vw;
  }
}

@media (min-width: 2000px) {
  .indentation {
    padding: 0 6vw;
  }
}

@media (min-width: 2050px) {
  .indentation {
    padding: 0 5vw;
  }
}

@media (min-width: 2100px) {
  .indentation {
    padding: 0 4.5%;
  }
}
