$bg: #eff3fe;
$text-light: #eff3fe;
$text-dark: #2f2e2f;
$text-body1: rgba(47, 46, 47, 0.8);
$link: #88abf1;
$link-dark: #2f2e2f66;
$link-active: #fff;
$light-link-button: #00255f;
$subtitle: #a1acd7;
$violet: #3c29a3;
$green: #bbf023;
$blue: #0149ed;
$softViolet: #8b82cb;
$red: #dc3c09;
