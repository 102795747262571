@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1100px) {
  .wrapper {
    margin-bottom: 60px;
  }
}

.subTiltleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

.subTitle {
  @extend %font-page-section-name;
  width: 150px;
}

@media (max-width: 1750px) {
  .subTitle {
    width: 130px;
  }
}

@media (max-width: 1285px) {
  .subTitle {
    width: 100px;
  }
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

.title {
  @extend %font-page-section-title;
  margin-bottom: 80px;
}

.projectsWrapper {
  display: flex;
  flex-direction: column;
}

.divider {
  border-bottom: 1px solid #a1acd7;
  margin: 50px 0;
}

@media (max-width: 1100px) {
  .divider {
    margin: 30px 0;
  }
}
