@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.container {
  cursor: pointer;
  padding: 20px;
}

@media (max-width: 1285px) {
  .container {
    padding: 15px;
  }
}

.containerTwo {
  padding: 0 200px 100px 200px;
}

.containerThree {
  padding: 100px 0px 100px 200px;
}

@media (min-width: 1930px) {
  .container {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 1880px;
  }

  .containerTwo {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 1520px;
  }

  .containerThree {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 1520px;
  }
}

@media (max-width: 1100px) {
  .container {
    padding: 40px 10px 0px 10px;
  }

  .containerTwo {
    padding: 0 16px 150px 16px;
  }

  .containerThree {
    padding: 150px 16px 0 16px;
  }
}

.wrapper {
  background-image: url('../../shared/assets/img/Main_Block.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  padding: 30px 0;
  gap: 50px;
  transition: background-image 3s;
  text-decoration: none;
}

@media (max-width: 1750px) {
  .wrapper {
    gap: 57px;
    padding: 25px 0;
  }
}

@media (max-width: 1540px) {
  .wrapper {
    gap: 50px;
  }
}

@media (max-width: 1285px) {
  .wrapper {
    gap: 50px;
    padding: 15px 0;
  }
}

@media (max-width: 1100px) {
  .wrapper {
    gap: 50px;
    padding: 20px;
    background-size: cover;
  }
}

.mainTitle {
  @extend %font-page-title;
  padding-left: 115px;
  padding-right: 100px;
  height: 240px;
  cursor: pointer;

  display: flex;
  align-items: center;
}

@media (max-width: 1750px) {
  .mainTitle {
    height: 216px;
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (max-width: 1540px) {
  .mainTitle {
    height: 180px;
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 1285px) {
  .mainTitle {
    height: 144px;
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (max-width: 1100px) {
  .mainTitle {
    height: 200px;
    margin-top: 50px;
    padding: 0;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1750px) {
  .wrap {
    gap: 12px;
  }
}

@media (max-width: 1285px) {
  .wrap {
    gap: 7px;
  }
}

@media (max-width: 1100px) {
  .wrap {
    gap: 20px;
  }
}

@media (max-width: 400px) {
  .wrap {
    gap: 35px;
  }
}

.subTitleWrapperHeight {
  height: 250px;
}

@media (max-width: 1750px) {
  .subTitleWrapperHeight {
    height: 196px;
  }
}

@media (max-width: 1540px) {
  .subTitleWrapperHeight {
    height: 170px;
  }
}

@media (max-width: 1285px) {
  .subTitleWrapperHeight {
    height: 140px;
  }
}

@media (max-width: 1100px) {
  .subTitleWrapperHeight {
    height: 120px;
  }
}

.subTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  padding-left: 15px;
  border-left: 2px solid $green;
  max-width: 600px;
  margin-left: 100px;
  cursor: pointer;
}

@media (max-width: 1750px) {
  .subTitleWrapper {
    margin-left: 75px;
    gap: 26px;
  }
}

@media (max-width: 1540px) {
  .subTitleWrapper {
    margin-left: 65px;
    gap: 20px;
  }
}

@media (max-width: 1285px) {
  .subTitleWrapper {
    margin-left: 55px;
    gap: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 1100px) {
  .subTitleWrapper {
    margin-left: 0;
    padding-left: 15px;
  }
}

.subTitle {
  @extend %font-page-subtitle;
  cursor: pointer;
}

.text {
  @extend %font-page-text;
  max-width: 500px;
  cursor: pointer;
}

@media (max-width: 1285px) {
  .text {
    max-width: 400px;
  }
}

.chooseBgWrapper {
  display: flex;
  align-self: flex-end;
  gap: 30px;
  padding: 0 50px;
  margin-bottom: 20px;
}

@media (max-width: 1750px) {
  .chooseBgWrapper {
    margin-bottom: 10px;
    padding: 0 35px;
    gap: 25px;
  }
}

@media (max-width: 1540px) {
  .chooseBgWrapper {
    padding: 0 40px;
    gap: 22px;
  }
}

@media (max-width: 1370px) {
  .chooseBgWrapper {
    padding: 0 16px;
    gap: 22px;
  }
}

@media (max-width: 1285px) {
  .chooseBgWrapper {
    padding: 0 30px;
    gap: 15px;
  }
}

@media (max-width: 1100px) {
  .chooseBgWrapper {
    display: none;
  }
}

.chooseBgButton {
  cursor: pointer;
  border: none;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  transition: all 0.5s;
}

.chooseBgButton img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

@media (max-width: 1750px) {
  .chooseBgButton {
    width: 75px;
    height: 75px;
  }
  .chooseBgButton img {
    width: 75px;
    height: 75px;
  }
}

@media (max-width: 1540px) {
  .chooseBgButton {
    width: 65px;
    height: 65px;
  }
  .chooseBgButton img {
    width: 65px;
    height: 65px;
  }
}

@media (max-width: 1285px) {
  .chooseBgButton {
    width: 57px;
    height: 57px;
  }
  .chooseBgButton img {
    width: 57px;
    height: 57px;
  }
}

.active {
  border: 5px solid $green;
  border-radius: 5px;
}

.chooseBgButton:hover {
  opacity: 0.8;
}

.chooseBgButton:active {
  opacity: 0.5;
}

.violet {
  filter: contrast(0.8) brightness(1.2) saturate(1.5) sepia(0.2);
}

.mobileLink {
  display: none;
}

@media (max-width: 1100px) {
  .mobileLink {
    display: block;
    width: fit-content;
  }
}

.indent {
  padding-left: 115px;
  padding-right: 100px;
}

@media (max-width: 1750px) {
  .indent {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (max-width: 1540px) {
  .indent {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 1285px) {
  .indent {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (max-width: 1100px) {
  .indent {
    padding: 0;
  }
}

.chooseBgWrapperMobile {
  display: none;
  align-self: center;
  padding: 0 30px;
  gap: 15px;
  margin-top: 20px;
}

@media (max-width: 1100px) {
  .chooseBgWrapperMobile {
    display: flex;
  }
}

.chooseBgButtonMobile {
  border-radius: 5px;
  cursor: pointer;
  border: none;
  width: 57px;
  height: 57px;
  transition: all 0.5s;
}

.chooseBgButtonMobile img {
  width: 57px;
  height: 57px;
  border-radius: 10px;
}

.chooseBgButtonMobile:hover {
  opacity: 0.8;
}

.chooseBgButtonMobile:active {
  opacity: 0.5;
}

.imgBlock {
  background-image: url('../../shared/assets/img/Main-page/about-us.jpg');
  background-size: cover;
  border-radius: 20px;
  height: 575px;
  margin-bottom: 100px;
}

@media (max-width: 1540px) {
  .imgBlock {
    height: 500px;
  }
}

@media (max-width: 1285px) {
  .imgBlock {
    height: 450px;
    border-radius: 10px;
  }
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  padding: 100px 200px 0 200px;
  gap: 100px;
}

@media (min-width: 1930px) {
  .footerWrapper {
    margin: 0 auto;
    max-width: 1520px;
  }
}

@media (max-width: 1750px) {
  .footerWrapper {
    gap: 85px;
  }
}

@media (max-width: 1540px) {
  .footerWrapper {
    gap: 70px;
  }
}

@media (max-width: 1285px) {
  .footerWrapper {
    gap: 60px;
  }
}

@media (min-width: 1930px) {
  .footerWrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1100px) {
  .footerWrapper {
    padding: 0 16px 0 16px;
    gap: 100px;
  }
}

.preloadedImages {
  visibility: hidden;
}

.preloadedImage {
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.bg1 {
  background-image: url('../../shared/assets/img/Main-page/equipment.webp');
}

.bg2 {
  background-image: url('../../shared/assets/img/Main-page/cutting-and-deboning-poultry.webp');
}

.bg3 {
  background-image: url('../../shared/assets/img/Main-page/automation-of-production.webp');
}

.bg4 {
  background-image: url('../../shared/assets/img/Main-page/engineering.webp');
}
