@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.container {
  display: flex;
  flex-direction: column;
  padding: 50px 120px 0px 120px;
  gap: 50px;
}

@media (min-width: 1930px) {
  .container {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 1520px;
  }
}

@media (max-width: 1285px) {
  .container {
    padding: 30px 120px 0px 120px;
  }
}

@media (max-width: 1100px) {
  .container {
    padding: 60px 16px 0 16px;
    gap: 0;
  }
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1285px) {
  .headerWrapper {
    gap: 30px;
  }
}

@media (max-width: 1100px) {
  .headerWrapper {
    gap: 20px;
    margin-bottom: 150px;
  }
}

.bgWrapper {
  background-image: url('../../shared/assets/img/Projects/engineering.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  height: 630px;

  display: flex;
  flex-direction: column;
  padding: 30px 80px;
  gap: 140px;
}

@media (max-width: 1750px) {
  .bgWrapper {
    height: 590px;
  }
}

@media (max-width: 1540px) {
  .bgWrapper {
    height: 530px;
    gap: 100px;
  }
}

@media (max-width: 1285px) {
  .bgWrapper {
    height: 400px;
    gap: 80px;
  }
}

@media (max-width: 1100px) {
  .bgWrapper {
    padding: 16px 16px 50px 16px;
    gap: 50px;
    height: auto;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;
}

@media (max-width: 1750px) {
  .textWrapper {
    gap: 25px;
  }
}

@media (max-width: 1540px) {
  .textWrapper {
    gap: 20px;
  }
}

@media (max-width: 1285px) {
  .textWrapper {
    gap: 15px;
  }
}

.title {
  @extend %font-page-title;
}

.line {
  border-bottom: 1px solid $subtitle;
  width: 1056px;
  align-self: center;
}

.text {
  @extend %font-page-text;
  align-self: center;
  max-width: 1050px;
}

@media (max-width: 1750px) {
  .line {
    width: 900px;
  }
  .text {
    max-width: 950px;
  }
}

@media (max-width: 1540px) {
  .line {
    width: 850px;
  }
  .text {
    max-width: 850px;
  }
}

@media (max-width: 1285px) {
  .line {
    width: 670px;
  }
  .text {
    max-width: 670px;
  }
}

@media (max-width: 1100px) {
  .line {
    width: 100%;
  }
}

.mobileLink {
  display: none;
}

@media (max-width: 1100px) {
  .mobileLink {
    display: flex;
    align-self: center;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 50px 80px 0 50px;
}

@media (max-width: 1100px) {
  .wrap {
    padding-left: 0;
    padding-right: 0;
  }
}
