@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
}

.subTiltleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .subTiltleWrapper {
    margin-bottom: 30px;
  }
}

.subTitle {
  @extend %font-page-section-name;
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

.aboutWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

@media (max-width: 1750px) {
  .aboutWrapper {
    gap: 170px;
  }
}

@media (max-width: 1540px) {
  .aboutWrapper {
    gap: 140px;
  }
}

@media (max-width: 1285px) {
  .aboutWrapper {
    gap: 80px;
  }
}

@media (max-width: 1100px) {
  .aboutWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  gap: 50px;
  margin-top: 12px;
}

@media (max-width: 1540px) {
  .wrap {
    max-width: 550px;
    gap: 40px;
  }
}

@media (max-width: 1285px) {
  .wrap {
    max-width: 400px;
    gap: 20px;
  }
}

@media (max-width: 1100px) {
  .wrap {
    gap: 20px;
  }
}

.title {
  @extend %font-page-section-title;
  align-self: flex-start;
}

.buttonsWrapper {
  display: flex;
  gap: 25px;
}

.text {
  @extend %font-page-section-text;
}

.button {
  border: 1px solid $violet;
  width: 57px;
  height: 57px;
  border-radius: 50%;
  cursor: pointer;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  opacity: 0.5;
}

.buttonWrapper {
  align-self: flex-start;
}
