@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
}

.subTiltleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .subTiltleWrapper {
    margin-bottom: 30px;
  }
}

.subTitle {
  @extend %font-page-section-name;
  width: 360px;
}

@media (max-width: 1750px) {
  .subTitle {
    width: 325px;
  }
}

@media (max-width: 1540px) {
  .subTitle {
    width: 290px;
  }
}

@media (max-width: 1285px) {
  .subTitle {
    width: 255px;
  }
}

@media (max-width: 1100px) {
  .subTitle {
    width: 965px;
  }
}

.subTitleLine {
  width: 500px;
  border: 1px solid $subtitle;
}

.title {
  @extend %font-page-section-title;
  margin-bottom: 100px;
}

@media (max-width: 1750px) {
  .title {
    margin-bottom: 85px;
  }
}

@media (max-width: 1540px) {
  .title {
    margin-bottom: 70px;
  }
}

@media (max-width: 1285px) {
  .title {
    margin-bottom: 60px;
  }
}

@media (max-width: 1100px) {
  .title {
    margin-bottom: 50px;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
}

.advantagesWrapper {
  display: flex;
}

@media (max-width: 1100px) {
  .advantagesWrapper {
    flex-direction: column;
  }
}

.advantages {
  display: flex;
  gap: 60px;
}

@media (max-width: 1750px) {
  .advantages {
    gap: 50px;
  }
}

@media (max-width: 1540px) {
  .advantages {
    gap: 40px;
  }
}

@media (max-width: 1285px) {
  .advantages {
    gap: 30px;
  }
}

.number {
  color: $violet;
  font-size: 20px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media (max-width: 1750px) {
  .number {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  .number {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  .number {
    font-size: 14px;
  }
}

.advantagesTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
}

.advantagesTextWrapper h6 {
  color: $text-dark;
  font-size: 26px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 1750px) {
  .advantagesTextWrapper h6 {
    font-size: 23px;
  }
}

@media (max-width: 1540px) {
  .advantagesTextWrapper h6 {
    font-size: 20px;
  }
}

@media (max-width: 1285px) {
  .advantagesTextWrapper h6 {
    font-size: 18px;
  }
}

.advantagesTextWrapper p {
  color: $text-body1;
  font-size: 20px;
  font-family: Evolventa;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  max-width: 400px;
}

@media (max-width: 1750px) {
  .advantagesTextWrapper p {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  .advantagesTextWrapper p {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  .advantagesTextWrapper p {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  .advantagesTextWrapper p {
    font-size: 14px;
  }
}

.lineVertical {
  border-right: 1px solid #a1acd7;
  height: 212px;
  margin: 0 15px 20px 15px;
}

@media (max-width: 1750px) {
  .lineVertical {
    height: 200px;
  }
}

@media (max-width: 1540px) {
  .lineVertical {
    height: 185px;
  }
}

@media (max-width: 1285px) {
  .lineVertical {
    height: 160px;
  }
}

.linesWrapper {
  display: flex;
  gap: 30px;
}

.lineHorizontal {
  border-bottom: 1px solid #a1acd7;
  width: 487px;
  margin-bottom: 15px;
}

@media (max-width: 1100px) {
  .linesWrapper,
  .lineHorizontal,
  .lineVertical {
    display: none;
  }
}

.mobileDivider {
  display: none;
}

@media (max-width: 1100px) {
  .mobileDivider {
    display: flex;
    border-bottom: 1px solid $subtitle;
    margin: 30px 0;
  }
}
