@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
}

@media (max-width: 1100px) {
  .wrapper {
    flex-direction: column;
  }
}

.number {
  color: $violet;
  font-family: Evolventa;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  // margin-right: 230px;
}

@media (max-width: 1750px) {
  .number {
    font-size: 18px;
    // margin-right: 200px;
  }
}

@media (max-width: 1540px) {
  .number {
    font-size: 16px;
    // margin-right: 180px;
  }
}

@media (max-width: 1285px) {
  .number {
    font-size: 14px;
    // margin-right: 150px;
  }
}

@media (max-width: 1100px) {
  .number {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.wrapperInfo {
  display: flex;
  width: 100%;
  justify-content: center;
}

@media (max-width: 1100px) {
  .wrapperInfo {
    flex-direction: column;
  }
}

.wrapper a img {
  width: 500px;
  height: 350px;
  border-radius: 12px;
  margin-right: 30px;
  cursor: pointer;
  transition: all 0.3s;
}

@media (max-width: 1750px) {
  .wrapper a img {
    width: 450px;
    height: 300px;
  }
}

@media (max-width: 1540px) {
  .wrapper a img {
    width: 400px;
    height: 250px;
  }
}

@media (max-width: 1285px) {
  .wrapper a img {
    width: 320px;
    height: 230px;
  }
}

@media (max-width: 1100px) {
  .wrapper a img {
    width: 100%;
    height: 270px;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.wrapper a img:hover {
  opacity: 0.9;
}

.wrapper a img:active {
  opacity: 0.8;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  width: 100%;
}

@media (max-width: 1750px) {
  .textWrapper {
    max-width: 550px;
  }
}

@media (max-width: 1540px) {
  .textWrapper {
    max-width: 500px;
  }
}

@media (max-width: 1366px) {
  .textWrapper {
    max-width: 450px;
  }
}

@media (max-width: 1285px) {
  .textWrapper {
    max-width: 400px;
  }
}

@media (max-width: 1100px) {
  .textWrapper {
    max-width: 100%;
  }
}

.date {
  color: $text-body1;
  font-family: Evolventa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 50px;
}

@media (max-width: 1750px) {
  .date {
    font-size: 14px;
  }
}

@media (max-width: 1540px) {
  .date {
    font-size: 12px;
  }
}

@media (max-width: 1285px) {
  .date {
    font-size: 10px;
  }
}

@media (max-width: 1100px) {
  .date {
    font-size: 12px;
    margin-bottom: 20px;
  }
}

.textWrapper div a {
  color: $text-dark;
  font-family: Evolventa;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 20px;
  text-decoration: none;
}

@media (max-width: 1750px) {
  .textWrapper div a {
    font-size: 24px;
  }
}

@media (max-width: 1540px) {
  .textWrapper div a {
    font-size: 22px;
  }
}

@media (max-width: 1285px) {
  .textWrapper div a {
    font-size: 20px;
  }
}

@media (max-width: 1100px) {
  .textWrapper div a {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.description {
  color: $text-body1;
  font-family: Evolventa;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

@media (max-width: 1750px) {
  .description {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  .description {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  .description {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  .description {
    font-size: 12px;
    margin-bottom: 20px;
  }
}

.textWrapper a {
  color: $violet;
  font-family: Evolventa;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3px;
}

@media (max-width: 1750px) {
  .textWrapper a {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  .textWrapper a {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  .textWrapper a {
    font-size: 14px;
    margin-bottom: 3px;
  }
}

@media (max-width: 1100px) {
  .textWrapper a {
    margin-bottom: 0px;
  }
}
