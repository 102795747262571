@import 'shared/styles/colors';

.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.wrapper img {
  width: 20px;
  height: 20px;
}

@media (max-width: 1750px) {
  .wrapper img {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 1540px) {
  .wrapper img {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 1285px) {
  .wrapper img {
    width: 14px;
    height: 14px;
  }
}

.mainCrumb {
  color: #fff;
  font-size: 16px;
  font-family: Evolventa;
  line-height: 150%;
  text-decoration: none;
}

.mainCrumbDark {
  color: $text-dark;
  font-size: 16px;
  font-family: Evolventa;
  line-height: 150%;
  text-decoration: none;
}

.text {
  cursor: default !important;
}

.crumb {
  font-size: 16px;
  font-family: Evolventa;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  cursor: default;
}

.crumbDark {
  font-size: 16px;
  font-family: Evolventa;
  line-height: 150%;
  color: $text-body1;
  text-decoration: none;
}

.secondCrumb {
  font-size: 16px;
  font-family: Evolventa;
  line-height: 150%;
  color: $text-body1;
  text-decoration: none;
  cursor: default;
}

@media (max-width: 1750px) {
  .mainCrumb,
  .mainCrumbDark,
  .crumb,
  .crumbDark,
  .secondCrumb {
    font-size: 15px;
  }
}

@media (max-width: 1540px) {
  .mainCrumb,
  .mainCrumbDark,
  .crumb,
  .crumbDark,
  .secondCrumb {
    font-size: 14px;
  }
}

@media (max-width: 1285px) {
  .mainCrumb,
  .mainCrumbDark,
  .crumb,
  .crumbDark,
  .secondCrumb {
    font-size: 12px;
  }
}
