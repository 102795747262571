@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1100px) {
  .wrapper {
    gap: 30px;
  }
}

.title {
  color: $violet;
  font-family: Evolventa;
  font-size: 40px;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 1750px) {
  .title {
    font-size: 36px;
  }
}

@media (max-width: 1540px) {
  .title {
    font-size: 32px;
  }
}

@media (max-width: 1285px) {
  .title {
    font-size: 28px;
  }
}

.achievementsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1100px) {
  .achievementsWrapper {
    gap: 10px;
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 1100px) {
  .wrap {
    flex-direction: column;
    gap: 10px;
  }
}

.achievement {
  display: flex;
  justify-content: space-between;
  padding: 35px 50px;
  border-radius: 14px;
  border: 1px solid $text-body1;
  width: 100%;
}

@media (max-width: 1750px) {
  .achievement {
    padding: 30px 45px;
  }
}

@media (max-width: 1540px) {
  .achievement {
    padding: 25px 40px;
  }
}

@media (max-width: 1366px) {
  .achievement {
    padding: 20px 35px;
  }
}

@media (max-width: 1285px) {
  .achievement {
    padding: 15px 30px;
  }
}

@media (max-width: 1100px) {
  .achievement {
    flex-direction: column-reverse;
    gap: 30px;
    padding: 20px;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1100px) {
  .textWrapper {
    gap: 10px;
  }
}

.textWrapper h6 {
  color: $violet;
  font-family: Evolventa;
  font-size: 24px;
  font-weight: 400;
  line-height: 120%;
}

.textWrapper p {
  color: $text-body1;
  font-family: Evolventa;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.number {
  color: $text-body1;
  font-family: Evolventa;
  font-size: 24px;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 1750px) {
  .textWrapper h6,
  .number {
    font-size: 22px;
  }
  .textWrapper p {
    font-size: 14px;
  }
}

@media (max-width: 1540px) {
  .textWrapper h6,
  .number {
    font-size: 20px;
  }
  .textWrapper p {
    font-size: 12px;
  }
}

@media (max-width: 1285px) {
  .textWrapper h6,
  .number {
    font-size: 18px;
  }
  .textWrapper p {
    font-size: 10px;
  }
}
