@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (min-width: 1930px) {
  .wrapper {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 1880px;
    width: 100%;
  }
}

@media (max-width: 1750px) {
  .wrapper {
    gap: 25px;
  }
}

@media (max-width: 1540px) {
  .wrapper {
    gap: 20px;
  }
}

@media (max-width: 1285px) {
  .wrapper {
    gap: 15px;
  }
}

@media (max-width: 1100px) {
  .wrapper {
    display: none;
  }
}

.mobileWrapper {
  display: none;
}

@media (max-width: 1100px) {
  .mobileWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.text {
  display: flex;
  align-self: flex-end;
  font-family: TTCommons;
  font-size: 22px;
  color: #fff;
  gap: 8px;
}

.text a {
  @extend %font-page-link-phone-white;
}

.text a:hover {
  opacity: 0.8;
}

.textDark {
  display: flex;
  align-self: flex-end;
  font-family: TTCommons;
  gap: 10px;
  font-size: 22px;
}

.textDark a {
  @extend %font-page-link-phone-grey;
  font-weight: 600;
  color: rgba(47, 46, 47, 0.8);
}

.textDark a:hover {
  opacity: 0.8;
}

.textDark a:active {
  opacity: 0.5;
}

.navWrapper {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 100%;
}

@media (min-width: 1930px) {
  .navWrapper {
    max-width: 1880px;
  }
}

.title {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  outline: none;
}

.title img {
  width: 177px;
  height: 28px;
}

@media (max-width: 1750px) {
  .title img {
    width: 170px;
    height: 25px;
  }
}

@media (max-width: 1540px) {
  .title img {
    width: 160px;
    height: 22px;
  }
}

@media (max-width: 1285px) {
  .title img {
    width: 140px;
    height: 19px;
  }
}

@media (max-width: 1100px) {
  .title img {
    width: 126.5px;
    height: 20px;
  }
}

.title:hover {
  opacity: 0.8;
}

.title:active {
  opacity: 0.5;
}

.titleBold {
  font-family: 'Fahkwang', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
  line-height: 39px;
}

.titleBoldDark {
  font-family: 'Fahkwang', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: $text-dark;
  line-height: 39px;
}

.titleBoldBlue {
  font-family: 'Fahkwang', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: $blue;
  line-height: 39px;
}

.titleLight {
  font-family: 'Fahkwang', sans-serif;
  font-weight: 300;
  font-size: 30px;
  color: $bg;
  line-height: 39px;
}

.titleLightDark {
  font-family: 'Fahkwang', sans-serif;
  font-weight: 300;
  font-size: 30px;
  color: $text-dark;
  line-height: 39px;
}

@media (max-width: 1750px) {
  .titleBold,
  .titleBoldDark,
  .titleBoldBlue,
  .titleLight,
  .titleLightDark {
    font-size: 28px;
  }
}

@media (max-width: 1540px) {
  .titleBold,
  .titleBoldDark,
  .titleBoldBlue,
  .titleLight,
  .titleLightDark {
    font-size: 26px;
  }
}

@media (max-width: 1285px) {
  .titleBold,
  .titleBoldDark,
  .titleBoldBlue,
  .titleLight,
  .titleLightDark {
    font-size: 24px;
  }
}

@media (max-width: 1100px) {
  .titleBold,
  .titleBoldDark,
  .titleBoldBlue,
  .titleLight,
  .titleLightDark {
    font-size: 18px;
  }
}

.nav {
  display: flex;
  align-self: center;
  gap: 30px;
}

@media (max-width: 1285px) {
  .nav {
    gap: 20px;
  }
}

.link {
  @extend %font-page-link-blue;
  align-self: center;
}

.link:hover {
  opacity: 0.8;
}

.link:active {
  opacity: 0.5;
}

.linkDark {
  @extend %font-page-link;
  align-self: center;
}

.linkDark:hover {
  opacity: 0.8;
}

.linkDark:active {
  opacity: 0.5;
}

.linkActive {
  text-decoration: none;
  color: $violet;
  font-size: 20px;
  padding: 5px 0 5px 0;
  border-top: 1px solid $violet;
  border-bottom: 1px solid $violet;
  height: fit-content;
  align-self: center;
}

@media (max-width: 1750px) {
  .linkActive {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  .linkActive {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  .linkActive {
    font-size: 14px;
  }
}

.linkActive:hover {
  opacity: 0.8;
}

.linkActive:active {
  opacity: 0.5;
}

.colorDark {
  color: $text-dark !important;
}
.menuButton {
  border: none;
  background: content-box;
  width: 24px;
  height: 24px;
}

.menuButton img {
  width: 24px;
  height: 24px;
}

.menuButtonWrapper {
  position: relative;
  display: flex;
}

.menuButtonWrapper:hover {
  .equipmentsHover {
    position: relative;
    border: 1px solid $softViolet;
    background-color: $softViolet;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    padding: 10px;
    opacity: 1;
    transition: all 0.2s;
  }

  @media (max-width: 1750px) {
    .equipmentsHover {
      padding: 8px;
    }
  }

  @media (max-width: 1540px) {
    .equipmentsHover {
      padding: 7px;
    }
  }

  @media (max-width: 1285px) {
    .equipmentsHover {
      padding: 7px;
    }
  }

  .equipmentsMenuWrapper {
    display: block;
    transition: all 0.2s;
  }
}

.equipmentsMenuWrapper {
  display: none;
  position: absolute;
  top: 90%;
}

.menuMobileWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -100%;
  top: 0;
  background-color: #fff;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 32px 32px 32px;
  z-index: 1;
}

.menuMobileWrapperWithOpenEquipment {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -100%;
  top: 0;
  background-color: #fff;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding: 60px 32px 32px 32px;
  z-index: 1;
}

.open {
  transform: translateX(100%);
}

.menuMobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuMobileNav {
  display: flex;
  flex-direction: column;
}

.menuMobileNav a {
  color: $text-dark;
  font-family: Evolventa;
  font-size: 20px;
  font-weight: 400;
  line-height: 120%;
  text-decoration: none;
}

.divider {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(60, 41, 163, 0.1);
}

.menuMobileFooter {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.phoneNumbers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.phoneNumbers a {
  color: $text-dark;
  font-family: Evolventa;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration: none;
}

.menuMobileButtonWrapper {
  display: flex;
}

.equipmentNavWrapper {
  display: none;
  flex-direction: column;
  margin-top: 30px;
  overflow-y: scroll;
}

.displayNone {
  display: none;
}

.displayFlex {
  display: flex;
}

.justifySelfEnd {
  margin-top: auto;
  padding-top: 20px;
}

.wrapperSticky {
  display: flex;
  position: fixed;
  top: 0;
  background: #fff;
  flex-direction: column;
  gap: 30px;
  padding: 20px 120px 20px 120px;
  opacity: 1;
  z-index: 1;
  border-radius: 10px;
  transition: all 0.5s;
  width: 100%;
}

@media (max-width: 1750px) {
  .wrapperSticky {
    gap: 25px;
  }
}

@media (max-width: 1540px) {
  .wrapperSticky {
    gap: 20px;
  }
}

@media (max-width: 1285px) {
  .wrapperSticky {
    gap: 15px;
  }
}

@media (max-width: 1100px) {
  .wrapperSticky {
    display: none;
  }
}

.linkSticky {
  @extend %font-page-link-blue;
  align-self: center;
  color: $violet;
}

.textSticky {
  display: flex;
  align-self: flex-end;
  font-family: TTCommons;
  font-size: 22px;
  color: $violet;
  gap: 8px;
}

.phoneLink {
  @extend %font-page-link-phone-white;
  color: $violet;
  font-weight: 700;
}

.phoneLink:hover {
  opacity: 0.8;
}

.fade {
  height: 0;
  padding: 0;

  opacity: 0;
}
