@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.container {
  display: flex;
  flex-direction: column;
  padding: 50px 200px 0px 200px;
  gap: 100px;
}

@media (min-width: 1930px) {
  .container {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 1520px;
  }
}

@media (max-width: 1100px) {
  .container {
    padding: 40px 16px 0 16px;
  }
}

.headerWrapper {
  padding: 50px 120px 0 120px;
}

@media (max-width: 1285px) {
  .headerWrapper {
    padding: 30px 120px 0 120px;
  }
}

@media (max-width: 1100px) {
  .headerWrapper {
    padding: 60px 16px 0 16px;
  }
}

.mobileWrapper {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

@media (max-width: 1285px) {
  .mobileWrapper {
    gap: 50px;
  }
}
