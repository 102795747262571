@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
}

.categoryWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categoryWrapper p {
  color: $text-dark;
  font-family: Evolventa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.categoryWrapper button {
  border: none;
  background-color: #fff;
}

.categoryWrapper button:hover {
  opacity: 0.8;
}

.categoryWrapper button:active {
  opacity: 0.5;
}

.categoryWrapper button img {
  width: 20px;
  height: 20px;
}

.productListWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.productWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.productWrapper img {
  width: 20px;
  height: 20px;
}

.productWrapper p {
  color: $violet;
  font-family: Evolventa;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}
