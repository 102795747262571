@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

@media (max-width: 1100px) {
  .infoWrapper {
    flex-direction: column;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  max-width: 622px;
  gap: 50px;
}

@media (max-width: 1750px) {
  .textWrapper {
    max-width: 500px;
  }
}

@media (max-width: 1540px) {
  .textWrapper {
    max-width: 520px;
  }
}

@media (max-width: 1285px) {
  .textWrapper {
    max-width: 450px;
  }
}

@media (max-width: 1100px) {
  .textWrapper {
    gap: 25px;
  }
}

.textWrapper h5 {
  color: $text-dark;
  font-family: Evolventa;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;
}

@media (max-width: 1750px) {
  .textWrapper h5 {
    font-size: 36px;
  }
}

@media (max-width: 1540px) {
  .textWrapper h5 {
    font-size: 32px;
  }
}

@media (max-width: 1285px) {
  .textWrapper h5 {
    font-size: 30px;
  }
}

@media (max-width: 1100px) {
  .textWrapper h5 {
    font-size: 30px;
  }
}

.textWrapper p {
  color: $text-body1;
  font-family: Evolventa;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  list-style-position: inside;
}

@media (max-width: 1750px) {
  .textWrapper p {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  .textWrapper p {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  .textWrapper p {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  .textWrapper p {
    font-size: 12px;
  }
}

.wrapperImgs {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1100px) {
  .wrapperImgs {
    display: none;
  }
}

.wrapperImgs div {
  display: flex;
  gap: 50px;
}

.wrapperImgs div img {
  width: 286px;
  height: 200px;
  border-radius: 14px;
}

@media (max-width: 1750px) {
  .wrapperImgs div img {
    width: 250px;
    height: 180px;
  }
}

@media (max-width: 1540px) {
  .wrapperImgs div img {
    width: 200px;
    height: 150px;
  }
}

@media (max-width: 1285px) {
  .wrapperImgs div img {
    width: 150px;
    height: 100px;
  }
}

.infoWrapper img {
  width: 45%;
  height: 1%;
  border-radius: 14px;
}

// @media (max-width: 1750px) {
//   .infoWrapper img {
//     // width: 780px;
//     height: 770px;
//   }
// }

// @media (max-width: 1540px) {
//   .infoWrapper img {
//     // width: 615px;
//     height: 630px;
//   }
// }

// @media (max-width: 1285px) {
//   .infoWrapper img {
//     // width: 420px;
//     height: 480px;
//   }
// }

@media (max-width: 1100px) {
  .infoWrapper img {
    display: none;
  }
}

.sliderMobileWrapper {
  display: none;
}

@media (max-width: 1100px) {
  .sliderMobileWrapper {
    display: block;
  }
}

.sliderMobileWrapper img {
  width: 98%;
  height: 450px;
}
