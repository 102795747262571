@import './colors';

%font-page-title {
  color: #fff;
  font-family: Evolventa;
  font-size: 90px;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 1750px) {
  %font-page-title {
    font-size: 80px;
  }
}

@media (max-width: 1540px) {
  %font-page-title {
    font-size: 70px;
  }
}

@media (max-width: 1285px) {
  %font-page-title {
    font-size: 60px;
  }
}

@media (max-width: 1100px) {
  %font-page-title {
    font-size: 40px;
    font-weight: 400;
  }
}

@media (max-width: 400px) {
  %font-page-title {
    font-size: 37px;
    font-weight: 400;
  }
}

%font-page-subtitle {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  font-family: Evolventa;
  line-height: 120%;
}

@media (max-width: 1750px) {
  %font-page-subtitle {
    font-size: 37px;
  }
}

@media (max-width: 1540px) {
  %font-page-subtitle {
    font-size: 33px;
  }
}

@media (max-width: 1285px) {
  %font-page-subtitle {
    font-size: 28px;
  }
}

@media (max-width: 1100px) {
  %font-page-subtitle {
    font-size: 20px;
  }
}

%font-page-text {
  color: $bg;
  font-size: 20px;
  font-weight: 400;
  font-family: Evolventa;
  line-height: 150%;
}

@media (max-width: 1750px) {
  %font-page-text {
    font-size: 20px;
  }
}

@media (max-width: 1540px) {
  %font-page-text {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  %font-page-text {
    font-size: 12px;
  }
}

%font-page-link {
  color: $text-body1;
  font-size: 20px;
  font-family: Evolventa;
  font-weight: 400;
  text-decoration: none;
}

%font-page-link-blue {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  font-family: Evolventa;
  font-weight: 400;
  text-decoration: none;
}

@media (max-width: 1750px) {
  %font-page-link-blue,
  %font-page-link {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  %font-page-link-blue,
  %font-page-link {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  %font-page-link-blue,
  %font-page-link {
    font-size: 13px;
  }
}

%font-page-link-phone-white {
  color: #fff;
  font-size: 20px;
  font-family: TTCommons;
  align-self: center;
  text-decoration: none;
}

%font-page-link-phone-grey {
  font-size: 20px;
  font-family: TTCommons;
  align-self: center;
  text-decoration: none;
}

@media (max-width: 1750px) {
  %font-page-link-phone-white,
  %font-page-link-phone-grey {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  %font-page-link-phone-white,
  %font-page-link-phone-grey {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  %font-page-link-phone-white,
  %font-page-link-phone-grey {
    font-size: 14px;
  }
}

%font-page-section-name {
  color: $subtitle;
  font-size: 20px;
  font-weight: 400;
  font-family: Evolventa;
  line-height: 120%;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

@media (max-width: 1750px) {
  %font-page-section-name {
    font-size: 18px;
  }
}

@media (max-width: 1540px) {
  %font-page-section-name {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  %font-page-section-name {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  %font-page-section-name {
    font-size: 12px;
  }
}

%font-page-section-title {
  color: $text-dark;
  font-size: 60px;
  font-weight: 400;
  font-family: Evolventa;
  line-height: 120%;
  text-transform: uppercase;
}

@media (max-width: 1750px) {
  %font-page-section-title {
    font-size: 55px;
  }
}

@media (max-width: 1540px) {
  %font-page-section-title {
    font-size: 45px;
  }
}

@media (max-width: 1366px) {
  %font-page-section-title {
    font-size: 40px;
  }
}

@media (max-width: 1285px) {
  %font-page-section-title {
    font-size: 35px;
  }
}

@media (max-width: 1100px) {
  %font-page-section-title {
    font-size: 35px;
  }
}

%font-page-section-text {
  color: $text-body1;
  font-size: 18px;
  font-family: Evolventa;
  line-height: 150%;
  font-weight: 400;
}

@media (max-width: 1750px) {
  %font-page-section-text {
    font-size: 17px;
  }
}

@media (max-width: 1540px) {
  %font-page-section-text {
    font-size: 16px;
  }
}

@media (max-width: 1285px) {
  %font-page-section-text {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  %font-page-section-text {
    font-size: 12px;
  }
}
